import axios from 'axios'
import { headers } from './header'

export const OfertaService = {
  paginacion,
  getRut,
  postToken,
  postEmision,
}

const URL_API = `${process.env.VUE_APP_API_HOST_VENTA}/api/v1`

async function paginacion(currentPage, catidadRegistros) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API}/productos/configuracion?page=${currentPage}&perPage=${catidadRegistros}&paginado=true`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => {
      const respuesta = res.data.data
      console.log(respuesta, 'servicesss')

      return respuesta
    })
    .catch(error => {
      console.log('error ', error)
    })
}

async function getRut(data) {
  const rutUser = data.rut
  const codigo = data.vendedorCodigo
  const punto = data.puntoVenta
  const requestOptions = {
    method: 'GET',
    url: `${URL_API}/oferta?rut=${rutUser}&puntoVenta=${punto}&vendedorCodigo=${codigo}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function postToken(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API}/token`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function postEmision(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API}/emision/core`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
