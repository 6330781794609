import axios from 'axios'
import { headers } from './header'

export const FirmaService = {
  postFirma,
  getFirma,
}

const URL_API_FIRMA = `${process.env.VUE_APP_URL_API_FIRMA}`

async function postFirma(firma) {
  const data = {
    rut64: firma.rut64,
    propuesta64: firma.propuesta64,
    urlPdf64: firma.urlPdf64,
    name64: firma.name64,
    idDocumento64: firma.idDocumento64,
    correoCliente64: firma.correoCliente64,
    tipoCodigoAcepta64: firma.tipoCodigoAcepta64,
    documentoPre64: firma.documentoPre64,
    tipoDocumentoCodigo64: firma.tipoDocumentoCodigo64,
    endoso64: firma.endoso64,
  }
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_FIRMA}`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function getFirma(firma) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_FIRMA}/${firma.rut64}/${firma.propuesta64}/${firma.urlPdf64}/${firma.name64}/${firma.idDocumento64}/${firma.correoCliente64}/${firma.endoso64}/${firma.tipoDocumentoCodigo64}/${firma.tipoCodigoAcepta64}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
