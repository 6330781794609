<template>
  <app-card-code
    :code="codeSnippets.formLayoutTabs"
    nombre = "vistaVenta"
  >
    <h3 class="ml-5">
      Venta
      <v-icon
        title="Refrescar vista"
        @click="restData"
      >
        {{ icons.mdiFindReplace }}
      </v-icon>
    </h3>
    <v-form
      ref="formDatosIniciales"
    >
      <v-row class="mb-10 mt-5 ml-3 mr-3">
        <!-- columna rut -->
        <v-col
          md="5"
          cols="10"
          class="input-rut"
        >
          <v-text-field
            name="inputRut"
            ref="inputRut"
            v-model="rut"
            v-mask="rut !== '' ? maskRut : null"
            dense
            hide-details
            label="Rut Asegurado"
            :rules="[rutValido.required]"
            :rut="true"
            :disabled="bloquearRut"
            @keypress="rutEsValido()"
          ></v-text-field>
        </v-col>

        <!-- columba boton buscar -->
        <v-col
          v-if="desactivarbuscador"
          md="1"
          cols="1"
        >
          <v-btn
            name="BotonBuscarRut"
            color="primary"
            elevation="2"
            fab
            small
            :loading="loading"
            :disabled="!rutEsValido()"
            @click="bucarRut(rut)"
          >
            <v-icon>{{ icons.mdiMagnify }}</v-icon>
          </v-btn>
        </v-col>
        <!-- BOTON SE ACTIVARA UNA VEZ QUE SEA VALIDO EL RUT -->
        <v-col
          md="1"
          cols="1"
        >
          <v-btn
            v-if="!desactivarbuscador"
            color="primary"
            elevation="2"
            fab
            small
            :loading="loading"
            @click="restData"
          >
            <v-icon>{{ icons.mdiFindReplace }}</v-icon>
          </v-btn>
        </v-col>

        <!-- columna producto -->
        <v-col
          v-if="respuestaRut"
          md="6"
          cols="12"
          name="productos"
        >
          <v-select
            name="producto"
            v-model="productos"
            autofocus
            :items="optionsProductos"
            label="Producto"
            item-text="descripcion"
            return-object
            dense
            :disabled="activProducto"
            :rules="[rulesProducto.required]"
            @change="selectProduct(productos)"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <!-- stepper headers -->
    <v-stepper
      v-if="respuestaRut"
      v-model="activeStep"
      flat
      class="transparent custom-header"
      alt-labels
    >
      <v-stepper
        v-if="respuestaRut"
        v-model="activeStep"
        flat
        class="transparent custom-header"
        alt-labels
      >
        <!-- Header -->
        <v-stepper-header>
          <!-- Header: Step 1 -->
          <v-stepper-step
            :complete="activeStep > 1"
            step="1"
          >
            <div class="d-flex flex-column text-center text-no-wrap">
              <span class="text--primary text-sm font-weight-semibold">Asegurado</span>
              <span class="text--secondary text-xs">Información básica</span>
            </div>
          </v-stepper-step>

          <v-divider></v-divider>

          <!-- Header: Step 2 -->
          <v-stepper-step
            v-if="stepActivar2"
            :complete="activeStep > valorStep2_1"
            :step="valorStep2"
          >
            <div class="d-flex flex-column text-center text-no-wrap">
              <span class="text--primary text-sm font-weight-semibold">Beneficiarios</span>
              <span class="text--secondary text-xs">Información de beneficiados</span>
            </div>
          </v-stepper-step>

          <v-divider></v-divider>

          <!-- Header: Step 3 -->
          <!-- <v-stepper-step
          v-if="stepActivar3"
          :complete="activeStep > valorStep3_1"
          :step="valorStep3"
        >
          <div class="d-flex flex-column text-center text-no-wrap">
            <span class="text--primary text-sm font-weight-semibold">Requisitos</span>
            <span class="text--secondary text-xs">Complete los requisitos</span>
          </div>
        </v-stepper-step>

        <v-divider></v-divider> -->

          <!-- Header: Step 4 -->
          <v-stepper-step
            :step="valorStep4"
          >
            <div class="d-flex flex-column text-center text-no-wrap">
              <span class="text--primary text-sm font-weight-semibold">Firma</span>
              <span class="text--secondary text-xs">Firma de documentos</span>
            </div>
          </v-stepper-step>
        </v-stepper-header>

        <!-- Stepper Items bodys -->
        <v-card v-if="respuestaRut">
          <v-stepper-items>
            <!-- Stepper Content: Step 1 -->
            <v-stepper-content step="1">
              <v-form
                ref="step1Form"
                class="pb-6 pt-2"
              >
                <v-row class="mb-5">
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      name="inputNombre"
                      ref="nombreCampo"
                      v-model="nombreUsuario"
                      dense
                      label="Nombres"
                      :rules="[validators.required, requerido.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      name="inputApellidos"
                      v-model="primerApellido"
                      dense
                      label="Primer Apellido"
                      :rules="[validators.required, requerido.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      name="inputSegundoApellido"
                      v-model="segundoApellido"
                      dense
                      hide-details
                      label="Segundo Apellido"
                    ></v-text-field>
                  </v-col>
                <!-- end col -->
                </v-row>

                <v-row class="mb-5 label-input-gh">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputFechaNacimiento"
                      v-model="birthDate"
                      type="date"
                      label="Fecha Nacimiento"
                      placeholder="YYYY-MM-DD"
                      :rules="[validators.required]"
                      required
                      dense
                      hide-details
                      disabled
                      class=""
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputCorreo"
                      v-model="correo"
                      dense
                      hide-details
                      label="Correo"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputNumeroTelefono"
                      v-model="phoneNO"
                      type="number"
                      label="No. Teléfono"
                      dense
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                <!-- end col -->
                </v-row>

                <hr>

                <v-row class="mt-3">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <h5>Dirección</h5>
                  </v-col>
                </v-row>

                <v-row class="mb-5">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputCalle"
                      v-model="calle"
                      dense
                      label="Calle"
                      :rules="[validators.required, requerido.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputNumero"
                      v-model="numero"
                      dense
                      label="Numero"
                      :rules="[validators.required, requerido.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- end col -->

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      name="inputDepartamento"
                      v-model="departamento"
                      dense
                      label="Departamento / Casa"
                      :rules="[validators.required, requerido.required]"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-5">
                  <v-col
                    cols="12"
                    md="4"
                    name="comuna"
                  >
                    <v-select
                      name="inputComuna"
                      v-model="country"
                      :items="optionsComunas"
                      label="Comuna"
                      item-text="descripcion"
                      item-value="codigo"
                      :rules="[validators.required]"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>

              <div class="d-flex justify-end">
                <v-btn
                  name="botonSiguiente"
                  color="primary"
                  class="me-2"
                  @click="handleStepValidation(1), siguiente()"
                >
                  Siguiente
                </v-btn>
              </div>
            </v-stepper-content>

            <!-- Stepper Content: Step 2 ------------------------------------------->
            <v-stepper-content
              v-if="stepActivar2"
              :step="valorStep2"
              name="step-2"
            >
              <v-form
                ref="step2Form"
                class="pb-6 pt-2"
              >
                <v-data-table
                  :headers="headers"
                  :items="beneficiarios"
                  sort-by="beneficiario"
                  hide-default-footer
                  class="elevation-1 mb-5"
                >
                  <template v-slot:top name="modalB">
                    <v-toolbar
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="dialog"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            name="botonBeneficiario"
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="resetFromBeneficiario"
                          >
                            Beneficiario
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="card-header-title">
                            <span class="text-h5 card-header-title-text">{{ formTitle }}</span>
                          </v-card-title>
                          <v-form
                            ref="formParticipacion"
                            @submit.prevent="save"
                            name="modalBeneficiario"
                          >
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    name="beneficiario"
                                  >
                                    <v-select
                                      name="inputParentesco"
                                      v-model="editedItem.parentesco"
                                      :items="optionsParentesco"
                                      label="Parentesco"
                                      item-text="nombre"
                                      return-object
                                      :rules="[rulesParticipacion.required]"
                                      @change="parentesco"
                                    ></v-select>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                  >
                                    <v-text-field
                                      name="inputParticipacion"
                                      v-model="editedItem.participacion"
                                      label="% Participación"
                                      type="number"
                                      min="1"
                                      max="100"
                                      :rules="[rulesParticipacion.required, rulesParticipacion.number, v => manageRulesParticipacion(v).result || manageRulesParticipacion(v).message]"
                                      @change="selectParticipacion"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      name="inputName"
                                      v-model="editedItem.nombre"
                                      label="Nombre Beneficiario"
                                      :rules="[validators.required]"
                                      :disabled="parentescoLegales"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      name="inputPrimerApellido"
                                      v-model="editedItem.primerApellido"
                                      label="Primer Apellido"
                                      :rules="[validators.required]"
                                      :disabled="parentescoLegales"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      name="inputSegundoApellido"
                                      v-model="editedItem.segundoApellido"
                                      label="Segundo Apellido"
                                      :disabled="parentescoLegales"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue-grey"
                                elevation="2"
                                @click="close"
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                name="botonGuardarBeneficiario"
                                color="primary"
                                elevation="2"
                                type="submit"
                              >
                                Guardar
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="dialogDelete"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-title class="text-h5">
                            ¿Desea eliminar beneficiario?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue-grey"
                              @click="closeDelete"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="error"
                              @click="deleteItemConfirm"
                            >
                              Eliminar
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      x-small
                      @click="editItem(item)"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      @click="deleteItem(item)"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:no-data>
                    No existen datos beneficiarios, ingresar nuevos datos
                  </template>
                </v-data-table>

                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="handleStepValidation(1, false), form1()"
                  >
                    Anterior
                  </v-btn>
                  <v-btn
                    name="botonSiguiente"
                    color="primary"
                    @click="siguiente_step2()"
                  >
                    Siguiente
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>
            <!-- *********************************************************** -->
            <v-stepper-content
              v-if="respuestaRut"
              :step="valorStep4"
              name="step-3"
            >
              <v-form
                ref="step4Form"
                class="pb-6 pt-2 multi-col-validation"
              >
                <v-row>
                  <v-col
                    cols="9"
                    md="5"
                  >
                    <v-text-field
                      name="inputTokenVenta"
                      v-model="tokenValidado"
                      dense
                      hide-details
                      label="Token "
                      :rules="[requerido.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="!tokenValido"
                    cols="9"
                    md="5"
                  >
                    <v-btn
                      name="botonValidarToken"
                      color="primary"
                      elevation="4"
                      small
                      :disabled="loadingToken"
                      :loading="loadingToken"
                      class="d-block"
                      @click="validarToken()"
                    >
                      Validar Token
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="!tokenValido"
                    md="1"
                    cols="1"
                  >
                    <v-btn
                      name="botonRefrechToken"
                      v-if="RefToken"
                      color="primary"
                      fab
                      small
                      @click="refrescarToken()"
                    >
                      <v-icon>{{ icons.mdiCached }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="tokenValido"
                    md="1"
                    cols="1"
                  >
                    <v-btn
                      name="botonTokenValido"
                      color="success"
                      elevation="2"
                      fab
                      small
                    >
                      <v-icon>{{ icons.mdiCheck }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="documentoActivo">
                  <!-- modal para pdf -->
                  <v-col
                    cols="10"
                    md="10"
                  >
                    <v-dialog
                      v-model="isDialogVisible"
                      fullscreen
                      transition="dialog-bottom-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-row>
                          <v-col
                            v-if="tokenValido"
                            md="0"
                            cols="0"
                            class="mt-3"
                          >
                            <v-btn
                              name="botonVerDocumento"
                              text
                              color="primary"
                              v-bind="attrs"
                              small
                              @click="VerDocumento()"
                              v-on="on"
                            >
                              {{ tituloDocumento }}
                            </v-btn>
                          </v-col>

                          <v-col
                            v-if="tokenValido"
                            md="1"
                            cols="1"
                          >
                            <v-btn
                              v-if="!firma_valido && !firma_noValido"
                              color="error"
                              elevation="2"
                              fab
                              small
                              title="Documento no firmado"
                            >
                              <v-icon>{{ icons.mdiClose }}</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="!firma_valido && firma_noValido"
                              color="info"
                              elevation="2"
                              fab
                              small
                              width="30px"
                              height="30px"
                              title="Firma de documento manual"
                            >
                              <v-icon>{{ icons.mdiPencil }}</v-icon>
                            </v-btn>

                            <v-btn
                              v-if="firma_valido && !firma_noValido"
                              color="success"
                              elevation="2"
                              fab
                              x-small
                              title="Documento firmado de forma digital"
                            >
                              <v-icon>{{ icons.mdiCheck }}</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>

                      <v-card tile name="modalDocumento">
                        <v-toolbar
                          dark
                          color="primary"
                        >
                          <div class=" row col-12">
                            <div class="col-md-2 col-2">
                              <v-btn
                                name="cerrarDocumento"
                                icon
                                dark
                                @click="cerrar_modal"
                              >
                                <v-icon>{{ icons.mdiClose }}</v-icon>
                              </v-btn>
                            </div>
                            <div class="col-md-7 col-7 tollbar-title">
                              <v-toolbar-title> {{ tituloDocumento }}</v-toolbar-title>
                            </div>
                            <div class="col-md-1 col-1 bonton-firma-icon">
                              <v-btn
                                v-if="!firmaActiva"
                                name="botonfirmaDocumentoIcon"
                                class=""
                                icon
                                dark
                                title="Firmar documento"
                                @click="firmarDocumento()"
                              >
                                <v-icon>{{ icons.mdiPencil }}</v-icon>
                              </v-btn>
                            </div>

                            <div class="col-md-3 col-3 bonton-firma">
                              <v-btn
                                v-if="!firmaActiva"
                                name="botonfirmaDocumento"
                                class=""
                                text
                                dark
                                title="Firmar documento"
                                @click="firmarDocumento()"
                              >
                                Firmar Documento
                              </v-btn>
                            </div>
                          </div>

                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-list
                          v-if="firmaActiva"
                          three-line
                          subheader
                          class="ocultar-scroll"
                          height="93vh"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <object
                                id="iframe_firma"
                                ref="iframe_firma"
                                :data="urlFirma"
                                width="100%"
                                height="800%"
                                frameborder="0"
                                allowfullscreen
                              >
                                <!-- sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals" -->
                                <p> <a
                                  href=""
                                >
                                  Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (1)
                                </a> </p>
                              </object>
                            </div>
                          </div>
                        </v-list>
                        <v-list
                          v-if="!firmaActiva"
                          three-line
                          subheader
                          class="ocultar-scroll"
                          height="93vh"
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <!-- EJECUTAMOS EL FIRMADOR -->
                              <object
                                width="100%"
                                height="800%"
                                type="application/pdf"
                                :data="documento"
                                frameborder="0"
                                allowfullscreen
                              >
                                <p> <a
                                  href=""
                                >
                                  Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (2)
                                </a> </p>
                              </object>
                            </div>
                          </div>
                        </v-list>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-form>

              <!-- Action Buttons -->
              <div class="d-flex justify-space-between">
                <v-btn
                  outlined
                  :disabled="tokenValido"
                  @click="handleStepValidation(1, false)"
                >
                  Anterior
                </v-btn>
                <v-btn
                  name="finalizarVenta"
                  color="primary"
                  :loading="loadingFinich"
                  :disabled="disabledFinich"
                  @click="finalizar()"
                >
                  Finalizar
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-card>
      </v-stepper>
    </v-stepper>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
      timeout="3000"
    >
      <span v-if="check"> <v-icon>{{ icons.mdiCheck }}</v-icon> </span>{{ textSnackbar }}
      <!-- <template v-slot:action="{ attrs2 }">
        <v-btn
          color="pink"
          text
          v-bind="attrs2"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template> -->
    </v-snackbar>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'

import { required } from '@core/utils/validation'
import {
  mdiClose,
  mdiCheck,
  mdiCached,
  mdiMagnify,
  mdiPencil,
  mdiDeleteOutline,
  mdiCloseThick,
  mdiFindReplace,
  mdiBookSearch,
} from '@mdi/js'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { maskRut, esValidoNumeroRut, cleanMaskRut } from '@/helpers/rut'
import { formLayoutTabs, dialogFullScreen } from './code'

import { OfertaService } from '../../../../service/oferta.service'
import { listaSelectService } from '../../../../service/listasSelects'
import { FirmaService } from '../../../../service/firmador.services'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    // modal del pdf
    const isDialogVisible = ref(false)
    const isDialogFirmadorVisible = ref(false)
    const notifications = ref(false)
    const sound = ref(true)
    const widgets = ref(false)
    const loading = ref(false)
    const loadingFinich = ref(false)
    const loadingToken = ref(false)
    const loadingToken2 = ref(false)
    const desactivarbuscador = ref(true)
    const bloquearRut = ref(false)
    const firma_valido = ref(false)
    const firma_noValido = ref(false)
    const disabledFinich = ref(false)

    // Stepper's active step
    const activeStep = ref(1)
    const requisitosActive = ref(false)

    // Data fromularios
    const rut = ref('')
    const nombreUsuario = ref('')
    const primerApellido = ref('')
    const segundoApellido = ref('')
    const birthDate = ref('')
    const correo = ref('')
    const phoneNO = ref('')
    const calle = ref('')
    const numero = ref('')
    const departamento = ref('')
    const country = ref('')
    const codigoComuna = ref('')

    const optionsProductos = ref([])
    const productos = ref(null)
    const optionsParentesco = ref([])
    const optionsComunas = ref([])
    const beneficiarios = ref([])

    const respuestaRut = ref(false)
    const stipeActive = ref(false)
    const activProducto = ref(true)

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(true)
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const totalParticipacion = ref(0)

    // Template refs
    const step1Form = ref(null)
    const step2Form = ref(null)
    const step3Form = ref(null)
    const step4Form = ref(null)
    const formDatosIniciales = ref(null)

    // step
    const valorStep2 = ref(null)
    const valorStep2_1 = ref(null)
    const valorStep3 = ref(null)
    const valorStep3_1 = ref(null)
    const valorStep4 = ref(null)
    const valorStep4_1 = ref(null)
    const stepActivar2 = ref(false)
    const stepActivar3 = ref(false)

    // variables
    const documento = ref(null)
    const parentescoLegales = ref(false)
    const sesion2 = ref(false)
    const tokenValidado = ref(null)
    const uuidCliente = ref(null)
    const documentoActivo = ref(false)
    const tituloDocumento = ref(null)
    const tokenValido = ref(false)
    const RefToken = ref(false)

    const correoCliente64 = ref('')
    const idDocumento64 = ref('')
    const name64 = ref('')
    const propuesta64 = ref('')
    const rut64 = ref('')
    const urlPdf64 = ref('')
    const urlFirma = ref('')
    const firmaActiva = ref(false)
    const check = ref(false)
    const tipoCodigoAcepta64 = ref('')
    const documentoPre64 = ref('')
    const tipoDocumentoCodigo64 = ref('')
    const endoso64 = ref('')

    const formParticipacion = ref(null)
    const tempBeneficiarioEdicion = ref({
      edicion: false,
      row: null,
    })

    // Step Validation (for `rules` prop of `v-stepper-step`)
    // It will be updated when step is validated
    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
      4: true,
    })
    const valFormDatosIniciales = () => {
      if (formDatosIniciales !== null) {
        return formDatosIniciales.value.validate()
      }

      return false
    }

    // Step Validation
    const handleStepValidation = (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument

      const templateRef = (() => {
        if (step === 1) return step1Form
        if (step === 2) return step2Form
        if (step === 3) return step3Form
        if (step === 4) return step4Form

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // If step is valid proceed to the next step
      const isStepValid = templateRef.value.validate() * valFormDatosIniciales()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1

        // Show Snackbar
        if (isFinalStep) {
          // ? Some Ajax request which saves form data in database
          // isFormSubmittedSnackbarVisible.value = true
          router.push('/')
        }
      }
    }

    return {
      formParticipacion,
      isDialogVisible,
      isDialogFirmadorVisible,
      notifications,
      desactivarbuscador,
      sound,
      widgets,
      maskRut,
      bloquearRut,
      firma_valido,
      firma_noValido,
      check,
      icons: {
        mdiClose,
        mdiCheck,
        mdiCached,
        mdiMagnify,
        mdiPencil,
        mdiDeleteOutline,
        mdiCloseThick,
        mdiFindReplace,
        mdiBookSearch,
      },
      codeSnippets: {
        formLayoutTabs,
        dialogFullScreen,
      },
      activeStep,

      // userData,
      rut,
      nombreUsuario,
      primerApellido,
      segundoApellido,
      birthDate,
      correo,
      phoneNO,
      calle,
      numero,
      departamento,
      country,
      codigoComuna,

      optionsProductos,
      productos,

      optionsParentesco,
      optionsComunas,

      respuestaRut,
      documento,
      activProducto,

      // Field Validators
      validators: {
        required,
      },
      rulesParticipacion: {
        required: value => !!value || 'Campo requerido.',
        number: value => typeof parseFloat(value, 10) === 'number' || 'Debe ingresar un número.',
      },
      rulesProducto: {
        required: value => !!value || 'El producto es requerido',

        // product: value => value === '' || 'El producto es requerido',
      },
      rutValido: {
        required: esValidoNumeroRut,
      },

      // Step Validations
      stepValidationResult,
      handleStepValidation,

      requisitosActive,
      stipeActive,

      parentescoLegales,

      // Template Refs
      step1Form,
      formDatosIniciales,
      step2Form,
      step3Form,
      step4Form,

      valorStep2,
      valorStep2_1,
      valorStep3,
      valorStep3_1,
      valorStep4,
      valorStep4_1,
      stepActivar2,
      stepActivar3,
      sesion2,

      tokenValidado,
      uuidCliente,
      documentoActivo,
      tituloDocumento,
      tokenValido,
      RefToken,
      loading,
      loadingFinich,
      loadingToken,
      loadingToken2,
      totalParticipacion,

      firmaActiva,

      // variables firma
      correoCliente64,
      idDocumento64,
      name64,
      propuesta64,
      rut64,
      urlPdf64,
      urlFirma,
      tipoCodigoAcepta64,
      documentoPre64,
      tipoDocumentoCodigo64,
      endoso64,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      isFormSubmittedSnackbarVisible2,
      disabledFinich,
      colorSnackbar,
      textSnackbar,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Nombres',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Primer Apellido', value: 'primerApellido' },
        { text: 'Segundo Apellido', value: 'segundoApellido' },
        { text: 'Parentesco', value: 'parentesco' },
        { text: '% Participación', value: 'participacion' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      beneficiarios,
      tempBeneficiarioEdicion,
      editedIndex: -1,
      editedItem: {
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: null,
        parentescoCodigo: null,
        participacion: null,
      },
      defaultItem: {
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: null,
        parentescoCodigo: null,
        participacion: null,
      },
      numberRut: {
        required: value => !!value || 'Campo requerido.',
        rut: value => {
          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(value)) {
            return 'Rut inválido.'
          }

          return true
        },
      },
      requerido: {
        required: value => !!value || 'Campo requerido.',
      },
      valFormDatosIniciales,
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Beneficiario' : 'Editar Beneficiario'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    window.addEventListener('message', event => {
      // IMPORTANT: check the origin of the data!
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaManual') {
        this.firmadorNoValido()
      }
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaDigital') {
        this.firmadorValido()
      }
    })
  },
  created() {
    this.initialize()
    this.getParentesco()
    this.getComunidad()
  },
  methods: {
    rutEsValido() {
      if (this.$refs.inputRut !== undefined) {
        if (this.$refs.inputRut.valid) {
          if (this.activeStep === 1) {
            this.iniciarStep()
          }

          return true
        }

        // this.restData()
      }

      return false
    },
    iniciarStep() {
      this.stipeActive = false
      this.valorStep4 = false
      this.stepActivar2 = false

      // this.activeStep = 1

      return true
    },
    getParentesco() {
      listaSelectService.getParentesco().then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.optionsParentesco = resp.data.data
        }
      })
    },
    getComunidad() {
      listaSelectService.getComuna().then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.optionsComunas = resp.data.data
        }
      })
    },

    // METODOS PARA BENEFCIARIO
    initialize() {
      this.beneficiarios = []
    },

    editItem(item) {
      this.editedIndex = this.beneficiarios.indexOf(item)
      this.editedItem = { ...item }
      if (item.parentesco === 'Herederos Legales') {
        this.parentescoLegales = true
      }
      this.tempBeneficiarioEdicion.edicion = true
      this.tempBeneficiarioEdicion.row = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.beneficiarios.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.beneficiarios.splice(this.editedIndex, 1)
      this.closeDelete()
      this.calculoBeneficiario()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    save() {
      const isFormValid = this.formParticipacion.validate()

      if (!isFormValid) return

      if (this.validate_beneficiario() === true) {
        if (this.editedIndex > -1) {
          Object.assign(this.beneficiarios[this.editedIndex], this.editedItem)
        } else {
          this.beneficiarios.push({ ...this.editedItem })
        }
        this.close()
      }

      this.calculoBeneficiario()
    },
    validate_beneficiario() {
      const suma = parseFloat(this.totalParticipacion)
      if (parseFloat(this.editedItem.participacion) <= 0) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participacion debe ser mayor a 0% '
        this.colorSnackbar = 'error'
      }
      if (suma > 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participacion no puede mayor a 100%'
        this.colorSnackbar = 'error'

        return false
      }
      if (this.editedItem.parentesco === 'Herederos Legales' && suma === 100) {
        return true
      }

      return true
    },
    manageRulesParticipacion(value) {
      const result = this.selectParticipacion(value)

      return result
    },
    calculoBeneficiario() {
      this.totalParticipacion = 0
      this.beneficiarios.forEach(element => {
        this.totalParticipacion += parseFloat(element.participacion)
      })

      if (this.totalParticipacion > 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación es mayor al 100%'
        this.colorSnackbar = 'error'

        return false
      }

      return true
    },
    parentesco(value) {
      if (value.nombre == 'Herederos Legales') {
        this.editedItem.participacion = 100
        this.editedItem.nombre = 'Herederos Legales'
        this.editedItem.primerApellido = 'Herederos Legales'
        this.editedItem.segundoApellido = 'Herederos Legales'
        this.editedItem.parentesco = value.nombre
        this.editedItem.parentescoCodigo = value.codigo
        this.parentescoLegales = true

        this.selectParticipacion()
      } else {
        this.editedItem.participacion = null
        this.editedItem.nombre = ''
        this.editedItem.primerApellido = ''
        this.editedItem.segundoApellido = ''
        this.editedItem.parentesco = value.nombre
        this.editedItem.parentescoCodigo = value.codigo
        this.parentescoLegales = false

        this.limpiarCamposFormBenef()
        this.editedItem.parentesco = value.nombre
        this.editedItem.parentescoCodigo = value.codigo
        if (this.formParticipacion !== null) {
          this.formParticipacion.resetValidation()
        }
      }
    },
    selectParticipacion(value) {
      // value = porcentaje de participacion
      let suma = 0
      const data = value !== '' ? parseFloat(value) : 0

      if (this.tempBeneficiarioEdicion.edicion === true) {
        suma = Math.abs(
          parseFloat(this.totalParticipacion) + data - parseFloat(this.tempBeneficiarioEdicion.row.participacion),
        )
      } else {
        suma = parseFloat(this.totalParticipacion) + data
      }

      if (this.totalParticipacion === 0 && data > 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación es mayor al 100%'
        this.colorSnackbar = 'error'

        return { result: false, message: this.textSnackbar }
      }
      if (suma > 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación es mayor al 100%'
        this.colorSnackbar = 'error'

        return { result: false, message: this.textSnackbar }
      }
      if (data <= 0) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación debe ser mayor a 0%'
        this.colorSnackbar = 'error'

        return { result: false, message: this.textSnackbar }
      }

      return { result: true, message: '' }
    },

    siguiente_step2() {
      if (this.totalParticipacion === 0) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El beneficiario es requerido'
        this.colorSnackbar = 'error'
        this.totalParticipacion = null
      } else if (this.totalParticipacion > 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación no puede ser mayor al 100%'
        this.colorSnackbar = 'error'
        this.totalParticipacion = null
      } else if (this.totalParticipacion < 100) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El total de participación  no puede ser menor al 100%'
        this.colorSnackbar = 'error'
        this.totalParticipacion = null
      } else {
        this.handleStepValidation(2)

        this.form2()
      }
    },
    limpiarCamposFormBenef() {
      this.editedItem.participacion = null
      this.editedItem.nombre = ''
      this.editedItem.primerApellido = ''
      this.editedItem.segundoApellido = ''
      this.editedItem.parentesco = ''
      this.editedItem.parentescoCodigo = ''
      this.parentescoLegales = false
    },
    resetFromBeneficiario() {
      this.limpiarCamposFormBenef()
      this.tempBeneficiarioEdicion = {
        edicion: false,
        row: null,
      }
      if (this.formParticipacion !== null) {
        this.formParticipacion.reset()
      }
    },

    // METODO PARA BUSQUEDA DEL RUT
    async bucarRut(itemRut) {
      this.formDatosIniciales.resetValidation()
      this.respuestaRut = false
      this.productos = null

      // this.step = '1'
      this.stepActivar2 = false
      this.stipeActive = true
      this.activProducto = true
      this.rut = cleanMaskRut(itemRut)

      const dataUser = JSON.parse(localStorage.getItem('userData'))
      const dataSucursal = JSON.parse(localStorage.getItem('userSucursal'))

      const data = {
        rut: this.rut,
        puntoVenta: dataSucursal.codigo,
        vendedorCodigo: dataUser.codigo,
      }
      this.loading = true

      await OfertaService.getRut(data)
        .then(resp => {
          const respuesta = resp.data.statusCode
          if (respuesta == 404 || respuesta == 400) {
            this.isFormSubmittedSnackbarVisible2 = true
            this.textSnackbar = resp.data.message
            this.colorSnackbar = 'error'
            this.respuestaRut = false
            this.stipeActive = false
            this.loading = false
          } else {
            this.desactivarbuscador = false
            this.respuestaRut = true
            this.activProducto = false
            this.stipeActive = false
            this.loading = false
            this.bloquearRut = true

            const dataClient = resp.data.data.cliente
            const datadireccion = resp.data.data.cliente.direccion
            const dataProducto = resp.data.data.productos

            this.nombreUsuario = dataClient.nombres
            this.primerApellido = dataClient.primerApellido
            this.segundoApellido = dataClient.segundoApellido
            this.birthDate = dataClient.fechaNacimiento
            if (dataClient.correo === 'Sin información') {
              this.correo = ''
            } else {
              this.correo = dataClient.correo
            }

            this.phoneNO = dataClient.telefonoPrincipal
            this.calle = datadireccion.calle
            this.numero = datadireccion.numero

            // this.departamento = datadireccion.localidadDescripcion

            // this.country = datadireccion.comunaDescripcion
            // this.codigoComuna = datadireccion.comunaCodigo
            for (let i = 0; i < this.optionsComunas.length; i++) {
              // if (datadireccion.comunaDescripcion === this.optionsComunas[i].nombre) {
              if (datadireccion.comunaCodigo === this.optionsComunas[i].codigo) {
                this.country = this.optionsComunas[i].nombre
                this.codigoComuna = this.optionsComunas[i].codigo
              } else {
                this.country = ''
              }
            }
            for (let i = 0; i < dataProducto.length; i++) {
              this.optionsProductos.push({
                descripcion: `${dataProducto[i].descripcion} ${dataProducto[i].monedaSeguro} ${new Intl.NumberFormat(
                  'de-DE',
                ).format(dataProducto[i].valorLocal)}`,
                codigo: dataProducto[i].codigo,
                beneficiario: dataProducto[i].beneficiario,
              })
            }
            this.uuidCliente = resp.data.data.uuid
          }
        })
        .catch(error => {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = error.data
          this.colorSnackbar = 'error'
          this.respuestaRut = false
          this.stipeActive = false
          this.loading = false
        })
    },

    // AL MOMENTO DE SELECCIONAR UN PRODUCTO VERIFICAMOS SI TIENE MAS DE UN STEP
    selectProduct(itemProduct) {
      console.log(itemProduct)
      if (itemProduct.beneficiario === true) {
        this.valorStep2 = '2'
        this.valorStep2_1 = 2
        this.valorStep4 = '3'
        this.valorStep4_1 = 3
        this.stepActivar2 = true
      } else {
        this.valorStep4 = '2'
        this.valorStep4_1 = 2
        this.valorStep2 = '0'
        this.valorStep2_1 = 0
        this.stepActivar2 = false
      }
    },

    // PASAR DE STEP
    siguiente() {
      const isFormValid = this.step1Form.validate()
      if (!isFormValid) return
      if (this.correo == null || this.phoneNO == null) {
        this.handleStepValidation(1, false)
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar =
          'Rut no posee correo o teléfono para continuar con la venta, dirigirse al departamento de relaciones comerciales'
        this.colorSnackbar = 'error'
      }
      if (this.productos === null) {
        this.handleStepValidation(1, false)
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El producto es requerido'
        this.colorSnackbar = 'error'
        this.activProducto = false
      }
      if (this.valorStep2_1 === 2) {
        if (this.productos === null) {
          this.handleStepValidation(1, false)
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = 'El producto es requerido'
          this.colorSnackbar = 'error'
          this.activProducto = false
        } else {
          this.activProducto = true
        }
      }

      if (this.valorStep4_1 === 2) {
        if (this.productos === null) {
          this.handleStepValidation(1, false)
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = 'El producto es requerido'
          this.colorSnackbar = 'error'
          this.activProducto = false
        } else {
          this.sesion2 = true
          this.activProducto = true
          this.form2()
        }
      }
    },

    form1() {
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      if (this.productos === null) {
        this.handleStepValidation(1, false)
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El producto es requerido'
        this.activProducto = false
      } else {
        this.sesion2 = true
        this.activProducto = true

        const data = {
          informacionCliente: {
            rut: cleanMaskRut(this.rut),
            nombres: this.nombreUsuario,
            primerApellido: this.primerApellido,
            segundoApellido: this.segundoApellido,
            fechaNacimiento: this.segundoApellido,
            correo: this.correo,
            telefono: this.phoneNO,
            direccion: {
              calle: this.calle,
              numero: this.numero,
              departamento: this.departamento,
              comunaCodigo: this.codigoComuna,
              comuna: this.country,
            },
          },
          informacionBeneficiarios: this.beneficiarios,
          productoCodigo: this.productos,
          uuid: this.uuidCliente,
          vendedorCodigo: dataUser.codigo,
        }
      }
    },

    form2() {
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      this.sesion2 = true
      const dataToken = {
        uuid: this.uuidCliente,
        productoCodigo: this.productos.codigo,
        rut: cleanMaskRut(this.rut),
        correo: dataUser.correo,
        telefono: this.phoneNO,
      }

      OfertaService.postToken(dataToken).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          const token = [{token: resp.data.data.token}]
          localStorage.setItem('tokenVenta', JSON.stringify(token))
          console.log(localStorage.getItem('tokenVenta'))
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = `Token enviado al email: ${dataToken.correo} / Teléfono: ${this.phoneNO}`
          this.colorSnackbar = 'success'
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          setTimeout(() => ( this.RefToken = true), 3000)
        }
      })
    },

    validarToken() {
      this.loadingToken = true
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      const data = {
        informacionCliente: {
          rut: cleanMaskRut(this.rut),
          nombres: this.nombreUsuario,
          primerApellido: this.primerApellido,
          segundoApellido: this.segundoApellido,
          fechaNacimiento: this.birthDate,
          correo: this.correo,
          telefono: this.phoneNO,
          direccion: {
            calle: this.calle,
            numero: this.numero,
            departamento: this.departamento,
            comunaCodigo: this.codigoComuna,
            comuna: this.country,
          },
        },
        informacionBeneficiarios: this.beneficiarios,
        productoCodigo: this.productos.codigo,
        uuid: this.uuidCliente,
        vendedorCodigo: dataUser.codigo,
        token: this.tokenValidado,
      }
      OfertaService.postEmision(data)
        .then(resp => {
          if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
            this.dialog = false
            this.documentoActivo = true
            this.tokenValido = true
            this.loadingToken = false
            this.tituloDocumento = resp.data.data.documentos[0].titulo

            this.isFormSubmittedSnackbarVisible2 = true
            this.textSnackbar = 'Token Validado'
            this.colorSnackbar = 'success'

            const decodedStr = `data:application/pdf;base64,${resp.data.data.documentos[0].documento}`
            this.documento = decodedStr

            this.correoCliente64 = resp.data.data.variablesFirma.correoCliente64
            this.idDocumento64 = resp.data.data.variablesFirma.idDocumento64
            this.name64 = resp.data.data.variablesFirma.name64
            this.propuesta64 = resp.data.data.variablesFirma.propuesta64
            this.rut64 = resp.data.data.variablesFirma.rut64
            this.urlPdf64 = resp.data.data.variablesFirma.urlPdf64
            this.tipoCodigoAcepta64 = resp.data.data.variablesFirma.tipoCodigoAcepta64
            this.documentoPre64 = resp.data.data.variablesFirma.documentoPre64
            this.tipoDocumentoCodigo64 = resp.data.data.variablesFirma.tipoDocumentoCodigo64
            this.endoso64 = resp.data.data.variablesFirma.endoso64
          }
          if (resp.data.statusCode === 400 || resp.data.statusCode === 404) {
            this.isFormSubmittedSnackbarVisible2 = true
            this.textSnackbar = resp.data.message
            this.colorSnackbar = 'error'
            this.documentoActivo = false
            this.tokenValido = false
            this.loadingToken = false

            // limpiamos campo token
            this.tokenValidado = ''
            setTimeout(() => (this.RefToken = true), 3000)
          }
        })
        .catch(error => {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = error.data.message
          this.colorSnackbar = 'error'
          this.documentoActivo = false
          this.tokenValido = false
          this.loadingToken = false
        })
    },

    refrescarToken() {
      const dataUser = JSON.parse(localStorage.getItem('userData'))

      // limpiamos campo token
      this.tokenValidado = ''

      this.loadingToken = true
      this.RefToken = false
      const dataToken = {
        uuid: this.uuidCliente,
        productoCodigo: this.productos.codigo,
        rut: cleanMaskRut(this.rut),
        correo: dataUser.correo,
        telefono: this.phoneNO,
      }
      OfertaService.postToken(dataToken).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          localStorage.setItem('tokenVenta', JSON.stringify(resp.data.data.token))
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = `Token enviado al siguiente correo: ${dataToken.correo}`
          this.colorSnackbar = 'success'
          this.loadingToken = false
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loadingToken = false
          setTimeout(() => (this.RefToken = true), 3000)
        }
      })
    },
    VerDocumento() {
      if (this.firmaActiva == true) {
        this.firmaActiva = true
      } else {
        this.firmaActiva = false
      }
    },
    async firmarDocumento() {
      const data = {
        rut64: this.rut64,
        propuesta64: this.propuesta64,
        urlPdf64: this.urlPdf64,
        name64: this.name64,
        idDocumento64: this.idDocumento64,
        correoCliente64: this.correoCliente64,
        tipoCodigoAcepta64: this.tipoCodigoAcepta64,
        documentoPre64: this.documentoPre64,
        tipoDocumentoCodigo64: this.tipoDocumentoCodigo64,
        endoso64: this.endoso64,
      }

      /*
      await FirmaService.getFirma(data).then(res => {
        console.log(res, 'firma')
        this.urlFirma = res.config.url
        this.firmaActiva = true
      })
      console.log(data)
      */
      this.urlFirma = `${process.env.VUE_APP_URL_API_FIRMA}/${data.rut64}/${data.propuesta64}/${data.urlPdf64}/${data.name64}/${data.idDocumento64}/${data.correoCliente64}/${data.endoso64}/${data.tipoDocumentoCodigo64}/${data.tipoCodigoAcepta64}`
      this.firmaActiva = true
    },
    firmadorValido() {
      this.firma_valido = true
      console.log('>>>> Validacion de firmador de doscumento desde el front >>>>>')
    },
    firmadorNoValido() {
      this.isFormSubmittedSnackbarVisible2 = true
      this.textSnackbar = 'Se requiere firma de documento manual. Por favor imprimir documento'
      this.colorSnackbar = 'info'
      this.loadingToken = false

      this.firma_valido = false
      this.firma_noValido = true
      this.firmaActiva = false
    },
    restData() {
      this.rut = ''
      this.nombreUsuario = ''
      this.primerApellido = ''
      this.segundoApellido = ''
      this.birthDate = ''
      this.correo = ''
      this.phoneNO = ''
      this.calle = ''
      this.numero = ''
      this.departamento = ''
      this.country = ''
      this.optionsProductos = []

      this.documento = null
      this.editarBeneficiario = null
      this.documentoActivo = false
      this.tokenValido = false

      this.valorStep4 = false
      this.stepActivar2 = false
      this.activeStep = 1

      this.productos = null
      this.beneficiarios = []

      this.notifications = false

      this.respuestaRut = false
      this.stipeActive = false
      this.activProducto = true

      this.documento = null
      this.parentescoLegales = false
      this.sesion2 = false
      this.tokenValidado = null
      this.uuidCliente = null
      this.documentoActivo = false
      this.tituloDocumento = null
      this.tokenValido = false
      this.RefToken = false
      this.totalParticipacion = 0
      this.desactivarbuscador = true
      this.bloquearRut = false
      this.firma_valido = false
      this.firma_noValido = false

      this.loadingFinich = false
      this.disabledFinich = false
      this.check = false
    },
    finalizar() {
      this.loadingFinich = true
      this.disabledFinich = true
      if (this.firma_valido === true) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'Venta realizada con exito, Firma digital'
        this.colorSnackbar = 'success'
        this.check = true
        setTimeout(() => this.restData(), 3000)
      }
      if (this.firma_valido === false && this.firma_noValido === true) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'Venta realizada, Documento firmado de forma manual con exito'
        this.colorSnackbar = 'success'
        this.check = true
        setTimeout(() => this.restData(), 3000)
      } else {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'El documento no ha sido firmado por el cliente'
        this.colorSnackbar = 'error'
        this.loadingFinich = false
        this.disabledFinich = false
        this.check = false
      }
    },
    cerrar_popos() {
      this.isFormSubmittedSnackbarVisible2 = false
    },
    cerrar_modal() {
      this.isDialogVisible = false
    },
  },
}
</script>
<style scoped>
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.card-header-title {
  /* background-color: rgb(20 20 20 / 25%);*/
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2);
  background: linear-gradient(to left, #2f80ed, #56ccf2);
  color: white;
}
.card-header-title-text {
  color: #ffffff;
}
.ocultar-scroll {
  overflow: hidden !important;
}
.v-label.v-label--active.v-label--is-disabled.theme--light {
  color: rgb(124 124 124) !important;
  cursor: not-allowed;
}
.theme--light.v-label--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(124 124 124) !important;
  cursor: not-allowed;
}
.label-input-gh {
  color: rgb(124 124 124) !important;
  cursor: not-allowed;
}
.v-input--is-disabled:not(.v-input--is-readonly) {
  cursor: not-allowed !important;
}
.bonton-firma-icon {
  display: none !important;
}
.bonton-firma {
  display: block !important;
}
@media (max-width: 600px) {
  .bonton-firma-icon {
    display: block !important;
  }
  .bonton-firma {
    display: none !important;
  }
}
@media (min-width: 800px) {
  .tollbar-title {
    margin-left: -95px;
  }
}
@media (min-width: 1000px) {
  .tollbar-title {
    margin-left: -115px;
  }
}
</style>
